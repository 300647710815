import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import HomeView from '../views/HomeView.vue';
import { Haa, OeReportType, OeReportSubType } from "@haa/fwk-h5/lib/index";
const { oeReport } = Haa.util().useOeReport();

const routes: Array<RouteRecordRaw> = [
  {
      path: '/',
      name: 'Home',
      component: HomeView,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const { useParams } = require("@/store/useParams");
  const { params } = useParams();
  if (to.path == "/" && params.skip == 1) {
    oeReport(
      OeReportType.Action,
      OeReportSubType.BtnClick,
      "Guide",
      "skip=1直接上报"
    );
    next("/home");
  } else {
    next();
  }
});

export default router;
