import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "cate-wrap" }
const _hoisted_2 = { class: "cate-title-wrap" }
const _hoisted_3 = { class: "cate-title" }
const _hoisted_4 = ["src"]
const _hoisted_5 = ["src"]
const _hoisted_6 = { class: "cate-banner-title" }
const _hoisted_7 = { class: "cate-box" }
const _hoisted_8 = ["onClick"]
const _hoisted_9 = ["src"]
const _hoisted_10 = { class: "item-info" }
const _hoisted_11 = { class: "item-title" }
const _hoisted_12 = { class: "item-desc" }

import { ref } from 'vue';

export default /*@__PURE__*/_defineComponent({
  __name: 'CateData',
  props: {
    category: {},
    banner: { type: Boolean }
  },
  setup(__props: any) {

const props = __props

const pathname = location.pathname.replace(/\/(index.html)?$/, "");

const CATEGORY_MAP = {
  hot: {
    name: '热门游戏',
    icon: `${pathname}/resource/icon/icon_game.png`,
  },
  newest: {
    name: '最新上线',
    icon: `${pathname}/resource/icon/icon_new.png`,
  },
  more: {
    name: '更多精彩游戏',
    icon: `${pathname}/resource/icon/icon_more.png`,
  },
};

const cateData = ref<GameType[]>([]);
const bannerData = ref<GameType>();
const getCateData = async () => {
  const now = new Date();
  const q = now.getDate() + "A" + now.getHours();
  fetch(pathname + `/resource/${props.category}.json?q=${q}`)
    .then((response) => response.json())
    .then(data => {
      let random = Math.floor(Math.random() * data.length);
      data.forEach((item, index) => {
        try {
          let prevs = JSON.parse(item.prev_url);
          item.banner = prevs[0].name;
        } catch (e) { }
        if (props.banner && index == random) {
          bannerData.value = item;
        }
        cateData.value.push(item);
      })
    })
}
getCateData();

const handleClick = (item: GameType) => {
  window.open(item.url, "_blank");
}

return (_ctx: any,_cache: any) => {
  const _directive_click = _resolveDirective("click")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("img", {
          src: CATEGORY_MAP[_ctx.category].icon
        }, null, 8, _hoisted_4),
        _createElementVNode("span", null, _toDisplayString(CATEGORY_MAP[_ctx.category].name), 1)
      ])
    ]),
    (_ctx.banner)
      ? _withDirectives((_openBlock(), _createElementBlock("div", {
          key: 0,
          class: "cate-banner",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (handleClick(bannerData.value)))
        }, [
          _createElementVNode("img", {
            src: bannerData.value?.banner
          }, null, 8, _hoisted_5),
          _createElementVNode("div", _hoisted_6, _toDisplayString(bannerData.value?.title_zh), 1)
        ])), [
          [
            _directive_click,
            [
        'BannerClick',
        _ctx.category,
        bannerData.value?.title_zh,
      ],
            "oeReport",
            { action: true }
          ]
        ])
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_7, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(cateData.value, (item, itemIndex) => {
        return _withDirectives((_openBlock(), _createElementBlock("div", {
          class: "cate-item",
          onClick: ($event: any) => (handleClick(item))
        }, [
          _createElementVNode("img", {
            class: "item-icon",
            src: item.icon
          }, null, 8, _hoisted_9),
          _createElementVNode("div", _hoisted_10, [
            _createElementVNode("div", _hoisted_11, _toDisplayString(item.title_zh), 1),
            _createElementVNode("div", _hoisted_12, _toDisplayString(item.description_zh), 1)
          ])
        ], 8, _hoisted_8)), [
          [
            _directive_click,
            [
            'CardClick',
            _ctx.category,
            item.title_zh,
          ],
            "oeReport",
            { action: true }
          ]
        ])
      }), 256))
    ])
  ]))
}
}

})