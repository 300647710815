<script setup lang="ts">

</script>

<template>
    <div class="loading-wrap">
        <van-loading size="60" font-size="40" :vertical="true">加载中...</van-loading>
    </div>
</template>

<style scoped lang="less">
.loading-wrap {
    width: 300px;
    height: 300px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 30px;
    position: fixed;
    top: 30%;left: 50%;
    margin-left: -150px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
