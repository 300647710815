import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "icp-wrapper" }
const _hoisted_2 = { class: "text" }
const _hoisted_3 = { class: "text" }

import DisclaimerPop from "./DisclaimerPop.vue";

import { ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'IcpComp',
  setup(__props) {

const appName = ref<string>(APP_NAME);
const company = ref<string>(APP_CONFIG_COMPANY_NAME);
const icp = ref<string>(APP_CONFIG_BEI_AN_CODE);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, "ICP备案/许可证号：" + _toDisplayString(icp.value), 1),
    _createElementVNode("p", _hoisted_3, _toDisplayString(appName.value) + "-" + _toDisplayString(company.value), 1),
    _createVNode(DisclaimerPop, { company: company.value }, null, 8, ["company"])
  ]))
}
}

})