<script setup lang="ts">
import { ref } from 'vue';
const props = defineProps<{
  category: string
  banner: boolean
}>()

const pathname = location.pathname.replace(/\/(index.html)?$/, "");

const CATEGORY_MAP = {
  hot: {
    name: '热门游戏',
    icon: `${pathname}/resource/icon/icon_game.png`,
  },
  newest: {
    name: '最新上线',
    icon: `${pathname}/resource/icon/icon_new.png`,
  },
  more: {
    name: '更多精彩游戏',
    icon: `${pathname}/resource/icon/icon_more.png`,
  },
};

const cateData = ref<GameType[]>([]);
const bannerData = ref<GameType>();
const getCateData = async () => {
  const now = new Date();
  const q = now.getDate() + "A" + now.getHours();
  fetch(pathname + `/resource/${props.category}.json?q=${q}`)
    .then((response) => response.json())
    .then(data => {
      let random = Math.floor(Math.random() * data.length);
      data.forEach((item, index) => {
        try {
          let prevs = JSON.parse(item.prev_url);
          item.banner = prevs[0].name;
        } catch (e) { }
        if (props.banner && index == random) {
          bannerData.value = item;
        }
        cateData.value.push(item);
      })
    })
}
getCateData();

const handleClick = (item: GameType) => {
  window.open(item.url, "_blank");
}
</script>

<template>
  <div class="cate-wrap">
    <div class="cate-title-wrap">
      <div class="cate-title">
        <img :src="CATEGORY_MAP[category].icon" />
        <span>{{ CATEGORY_MAP[category].name }}</span>
      </div>
    </div>
    <div
      v-if="banner"
      class="cate-banner"
      v-click:oeReport.action="[
        'BannerClick',
        category,
        bannerData?.title_zh,
      ]"
      @click="handleClick(bannerData)"
    >
      <img :src="bannerData?.banner" />
      <div class="cate-banner-title">{{ bannerData?.title_zh }}</div>
    </div>
    <div class="cate-box">
      <template v-for="(item, itemIndex) in cateData">
        <div
          class="cate-item"
          v-click:oeReport.action="[
            'CardClick',
            category,
            item.title_zh,
          ]"
          @click="handleClick(item)"
        >
          <img class="item-icon" :src="item.icon" />
          <div class="item-info">
            <div class="item-title">{{ item.title_zh }}</div>
            <div class="item-desc">{{ item.description_zh }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<style scoped lang="less">
.cate-wrap {
  margin-top: 30px;
  padding: 0 20px;
  .cate-title-wrap {
    display: flex;
    margin-bottom: 30px;
    .cate-title {
      background-image: url("@/assets/images/common/cate_bg.png");
      background-size: 100% 100%;
      height: 64px;
      display: flex;
      align-items: center;
      padding: 0 60px 0 30px;
      img {
        width: 40px;
        height: 40px;
        margin-right: 20px;
      }
      span {
        font-size: 30px;
        color: #fff;
      }
    }
  }
  .cate-banner {
    background-color: #fff;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;
    margin-bottom: 30px;
    box-shadow: 0px 0px 20px 0px rgba(13, 161, 244, 0.1);
    img {
      width: 100%;
      border-radius: 10px;
    }
    .cate-banner-title {
      font-size: 30px;
      line-height: 44px;
      color: #000;
      font-weight: bold;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .cate-box {
    display: flex;
    flex-wrap: wrap;
    .cate-item {
      width: 330px;
      background-color: #fff;
      border-radius: 10px;
      padding: 20px 10px;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      margin-bottom: 30px;
      box-shadow: 0px 0px 20px 0px rgba(13, 161, 244, 0.1);
      &:nth-child(2n) {
        margin-left: 30px;
      }
      .item-icon {
        width: 85px;
        height: 85px;
        margin-right: 10px;
        border-radius: 10px;
      }
      .item-info {
        width: 220px;
        display: flex;
        flex-direction: column;
        .item-title {
          font-size: 30px;
          line-height: 44px;
          color: #000;
          font-weight: bold;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .item-desc {
          font-size: 25px;
          line-height: 35px;
          color: #626262;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}
</style>
